require(['jquery'], function ($) {

  if ($(".xm-grid-product").length > 0 && $('button.btn-cart').length > 0) {
    $('body').append("<div class='add-cart-fixed' style='display: none;'><span>" + $('button.btn-cart:first').text() + "</span></div>");
    $('body').on('click', '.add-cart-fixed', function () {
      $([document.documentElement, document.body]).animate({
        scrollTop: $(".product-shop").offset().top
      }, 1000);

      $('.product-options').addClass('border-out');

    });
  }

  var isScrolledIntoView = function (elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  };

  /* floating header */
  var halfScreenSize = ($(document).width() * 0.5);
  var topSize = $('#header').height();
  $(window).scroll(function () {
    $this = $(this);
    if ($this.scrollTop() >= topSize) {
      if (!$('#header').hasClass('floating')) {
        $('body').css('padding-top', $('.header-primary-container').height());
        $('#header').addClass('floating');
      }
    }
    if ($this.scrollTop() < topSize) {
      if ($('#header').hasClass('floating')) {
        $('body').attr('style', '');
        $('#header').removeClass('floating');
        $('#header').attr('style', '');
      }
    }

    if ($('button.btn-cart').length > 0 && $(document).width() <= 960) {
      var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
      if (isVisibleOnScreen) {
        $('.add-cart-fixed').hide();
        $('.add-cart-fixed').width('50%');
      } else {
        if ($('.add-cart-fixed').width() === halfScreenSize) {
          $('.add-cart-fixed').show().animate({ width: '100%' });
        }
      }
    }
  });

  if ($("#mini-cart").length > 0) {
    $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
  }

  $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");

  $('nav.nav').prepend("<div class='show-below-960 nav-logo'>" + $(".logo-wrapper").html() + "</div>");
  $('nav.nav').prepend("<div class='show-below-960 nav-cart'>" + $("#mini-cart").html() + "</div>");

  if ($(".form-search").length > 0) {
    $('.mobile-top-container .nav-cart').after("<div class='show-below-960 item item-right nav-search'>" + $(".search-wrapper").html() + "</div>");
  }
  
  if ($(".variant-option").length > 0) {
    if ($(".variant-option .swatches-con .swatch img").length > 0) {
      $(".variant-option .swatches-con").css('display', 'block');
    }
  }

  /*
  $(".mobnav-trigger-wrapper").removeClass("grid-full");

  if ($(".isPublic").length > 0 && $(".top-links").length > 0) {
    $("#nav").prepend("<li class='nav-item level0 level-top show-below-960'>" + $(".top-links").html() + "</li>");
  } else {
    $("#nav").prepend("<li class='nav-item level0 level-top show-below-960'>" + $(".header-top-container").html() + "</li>");
  }
  $("#nav").removeClass("grid12-8");

  $("a.mobnav-trigger").attr("href", "#nav");
   */

  $(".module-sub-categories .page-item.parent > .module-sub-categories-title").after('<span class="opener">&nbsp;</span>');
  $('body').on('click', '.opener', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }
  });

  $(window).on('resize', onResize);

  $(document).ready(function () {
    onResize();
  });

  var onResize = function () {
    if ($(window).width() < 960) {
      $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
          e.preventDefault();
          $(".nav-search").addClass("fly-out");
          $(".nav-search .form-search .input-text").focus();
          $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
      });

      if ($(".module-multi-location").length > 0 && $("#xmMmenu .mm-listview > .multi-location-wrapper").length === 0) {
        $("<li class='mm-listitem switch-location module-multi-location'>" + $(".module-multi-location > a")[0].outerHTML + "<li>").appendTo("#xmMmenu .mm-listview:first");
        $(".mm-listitem.switch-location > a").addClass("mm-listitem__text");
        $(".popup-module-modal").appendTo("body");
      }
      
    }
  };

  $(document).on('click', '.nav-search .button-close', function (e) {
    e.preventDefault();
    $(".nav-search").removeClass("fly-out");
  });

});